import React, { useRef, useState } from "react"
import * as Yup from "yup"
import { Helmet } from "react-helmet"
import { Formik } from "formik"

import { dataURItoBlob } from "../../utils/form-data"
import { standardRequiredText } from "../../constants"
import {
  submitForm,
  handleSubmitResponse,
  jsonToFormData,
} from "../../services"

import { CardGrey } from "../../style/containers"
import { NestedCounterOlWrapper } from "../../style/lists"
import { TableEqualColumns, Table, Sth } from "../../style/tables"
import {
  LCard,
  Col2,
  Col2Constant,
  FieldSet,
  HeaderWithLogo,
} from "../../style/containers"

import Form from "../../components/form/FormHelper"
import Layout from "../../components/layout"
import SButton from "../../components/button"
import Signature from "../../components/Signature"
import { FormikCheckbox, FormikInput } from "../../components/form"
import { SImage } from "../../components/starterContent"

// IMAGES
import SafetySignMandatory from "../../assets/images/safety-signs-mandatory.png"
import SafetySignProhibition from "../../assets/images/safety-signs-prohibition.png"
import SafetySignSafeConditions from "../../assets/images/safety-signs-safe-condition.png"
import SafetySignWarning from "../../assets/images/safety-signs-warning.png"
import ManualHandlingStep1 from "../../assets/images/manual-handling-1.jpeg"
import ManualHandlingStep2 from "../../assets/images/manual-handling-2.jpeg"
import ManualHandlingStep3 from "../../assets/images/manual-handling-3.jpeg"
import ManualHandlingStep4 from "../../assets/images/manual-handling-4.jpeg"
import RettigLogo from "../../assets/images/logos/rettig-logo.png"

const RettigFormGateshead = () => {
  const sigCanvas = useRef({})
  const [signatureDrawn, setSignatureDrawn] = useState()
  const [errorText, setErrorText] = useState([])

  const onSubmit = async (values, actions) => {
    if (signatureDrawn) {
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/ false || !!document.documentMode

      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia

      let file

      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png")
      const blobContent = dataURItoBlob(dataURL)
      const formData = jsonToFormData(values)

      if (isEdge || isIE) {
        file = new Blob([blobContent], "signature.png", {
          type: "image/png",
        })
      } else {
        file = new File([blobContent], "signature.png", { type: "image/png" })
      }

      formData.append("signature", file)

      let res = await submitForm(formData, "")
      handleSubmitResponse(res, setErrorText)
    } else {
      setErrorText([
        "You must sign a signature before submitting. If you've already added a signature please clear and sign again",
      ])
    }
    actions.setSubmitting(false)
  }
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rettig Induction Form</title>
      </Helmet>
      <LCard>
        <Formik
          initialValues={{
            full_name: "",
            understands_absence_procedure: 0,
          }}
          validationSchema={Yup.object().shape({
            full_name: Yup.string().required(standardRequiredText),
            understands_absence_procedure: Yup.number().oneOf(
              [1],
              standardRequiredText
            ),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <HeaderWithLogo>
                <h1>Rettig Induction (Gateshead)</h1>
                <img src={RettigLogo} alt="Rettig Logo" />
              </HeaderWithLogo>
              <FieldSet>
                <h2>
                  Induction Health, Safety &amp; Environmental Information
                </h2>
                <p>
                  The following information is intended as an employee/agency
                  guide to aid effective Health, Safety and Environmental
                  management within the Rettig ICC business.
                </p>
                <h3>Employee / Agency Co-Operation</h3>
                <p>
                  Employee/agency co-operation and participation is essential
                  for the Company to provide a safe and healthy workplace.
                  Please help by taking reasonable care of yourselves and
                  others, report all hazards and dangerous occurrences and
                  follow specific safety rules and procedures.
                </p>
                <h3>First Aid &amp; Accident Reporting</h3>
                <p>
                  A number of personnel on site are trained first-aiders, (see
                  notice boards) Please make yourself familiar with the
                  first-aiders covering your particular shift.
                </p>
                <p>
                  Should first aid treatment be required, contact your nearest
                  first-aider for assistance. If a first-aider cannot be
                  immediately located, please contact your Shift Manager or Team
                  Leader.
                </p>
                <p>
                  Please ensure that{" "}
                  <b>
                    all accidents, however minor, are recorded in the ACCIDENT
                    BOOK
                  </b>
                  , which is located in the Medical Room. It is the
                  responsibility of first-aiders to ensure that such accidents
                  are recorded in the book and RLP with feedback to the shift
                  Manager.
                </p>
                <h3>Fire Safety</h3>
                <p>
                  If a fire is detected the alarm must be raised immediately and
                  the building evacuated as quickly as possible. When the alarm
                  sounds, make your way to the nearest fire exit and on to your
                  fire assembly point, (Front exit point – Staff, Opposite rear
                  security – process). Do not re-enter the building unless told
                  to do so by someone in authority.
                </p>
                <p>
                  If you discover the fire, inform Security of its location
                  immediately upon evacuation.
                </p>
                <p>
                  Please make yourself familiar with the locations of the
                  following:
                </p>
                <ul>
                  <li>Fire exits</li>
                  <li>Fire extinguishers</li>
                  <li>Fire alarm call points</li>
                  <li>Fire assembly point</li>
                </ul>
                <h3>Manual Handling</h3>
                <p>
                  When you need to lift something, please make sure that you use
                  the correct lifting techniques. Even a small weight can cause
                  injury if not handled correctly.
                </p>
                <h5>Principles of Manual Handling</h5>
                <NestedCounterOlWrapper>
                  <ol>
                    <li>
                      The manual handling of loads should only be considered
                      where an alternative method is unavailable.
                    </li>
                    <li>
                      A trolley or sack barrow can be used or ask for assistance
                      from a Fork Truck driver.
                    </li>
                    <li>
                      Do not undertake any manual handling if you are pregnant
                      or have an injury or illness that could be worsened by the
                      task.
                    </li>
                    <li>
                      Assess the load and if you feel that it is too heavy for
                      you to lift, get assistance.
                    </li>
                    <li>
                      When lifting, please ensure that the following principles
                      are followed:
                      <br />
                      <br />
                      <Col2Constant>
                        <div>
                          4.1 Place one foot in front of the other. Leading foot
                          first. Plan your route, any resting stages? No
                          obstructions?
                          <SImage
                            src={ManualHandlingStep1}
                            alt="Man stood above box"
                          />
                        </div>
                        <div>
                          4.2 Unlock the knees Assess the load Get a firm grip.
                          <SImage
                            src={ManualHandlingStep2}
                            alt="Man bending down to pick up box"
                          />
                        </div>
                        <div>
                          4.3 Start the lift by driving with the legs
                          <SImage
                            src={ManualHandlingStep3}
                            alt="Man half stood up with box"
                          />
                        </div>
                        <div>
                          4.4 Keep the load as close to the body as possible,
                          stick to your planned route
                          <SImage
                            src={ManualHandlingStep4}
                            alt="Man stood up with box"
                          />
                        </div>
                      </Col2Constant>
                    </li>
                  </ol>
                </NestedCounterOlWrapper>
              </FieldSet>
              <FieldSet>
                <h3>Personal Protective Equipment</h3>
                <p>
                  Personal Protective Equipment (PPE) is issued where hazards
                  cannot be controlled by other methods. When using PPE, please
                  adhere to the following requirements
                </p>
                <ul>
                  <li>
                    PPE must be used at all times whenever the hazard is
                    present.
                  </li>
                  <li>
                    Ensure that you are wearing the correct size of PPE and that
                    it fits correctly.
                  </li>
                  <li>
                    Check PPE regularly to ensure that it is in good condition
                    and replace if required.
                  </li>
                  <li>
                    It is your responsibility to look after the equipment you
                    are supplied with.
                  </li>
                  <li>
                    The requirement to wear PPE will normally be indicated by
                    notices but general site requirements are as follows:
                    <ul>
                      <li>Safety footwear</li>
                      <li>Overalls</li>
                      <li>Gloves</li>
                      <li>Cold weather clothing as and when required</li>
                      <li>High visibility vests (where appropriate)</li>
                    </ul>
                  </li>
                </ul>
              </FieldSet>
              <FieldSet>
                <h3>Safe Use Of Tools &amp; Machinery</h3>
                <p>
                  In order to minimise the risk of injury when operating
                  machinery, please ensure that the following rules are adhered
                  to:
                </p>
                <ul>
                  <li>
                    Ensure that all safety devices are in place and operational.
                  </li>
                  <li>
                    Do not use MHE unless you are authorised, licensed and
                    trained to do so
                  </li>
                  <li>
                    Ensure that you know how to stop the machine in the event of
                    an emergency
                  </li>
                  <li>Wear all required Personal Protective Equipment</li>
                  <li>
                    When changing batteries ect ensure that it is suitably
                    isolated to prevent it being re-started inadvertently.
                  </li>
                  <li>
                    Report all faults on MHE to your Shift Manager or Team
                    Leader immediately.
                  </li>
                  <li>
                    Maintain good housekeeping by ensuring that the
                    parking/charging area around the machine is tidy and free of
                    obstructions
                  </li>
                  <li>
                    Only use tools for the purpose for which they have been
                    designed
                  </li>
                  <li>
                    Check tools for damage before use and withdraw from use if
                    any faults are found which could cause a safety risk
                  </li>
                </ul>
              </FieldSet>
              <FieldSet>
                <h3>Housekeeping</h3>
                <p>
                  Many injuries within the workplace can be prevented through
                  effective housekeeping and it is the responsibility of
                  everyone on site to ensure that the risk of slips, trips and
                  falls is minimised.
                </p>
                <ul>
                  <li>
                    Ensure that all work areas are clear of obstructions and
                    trip hazards such as components, packaging and broken
                    pallets.
                  </li>
                  <li>
                    Mop up all spills immediately, contain spills from entering
                    the surface water drains (spill kits available on site)
                  </li>
                  <li>
                    Ensure that walkways, exits and emergency equipment are free
                    from obstructions
                  </li>
                  <li>
                    Dispose of all waste materials in the bins/skips provided
                  </li>
                </ul>
              </FieldSet>
              <FieldSet>
                <h3>Dangerous Substances</h3>
                <p>
                  Some substances used on site may cause illness or injury
                  through inhalation, ingestion, or contact with the skin. It is
                  a requirement under the COSHH Regulations that these products
                  must display a label indicating the nature of the hazard.
                  Further information is also available on Data Sheets, which
                  can be made available as and when required.
                </p>
                <p>When using dangerous substances always:</p>
                <ul>
                  <li>Wear appropriate personal protective equipment</li>
                  <li>
                    Do not use any substance you are unfamiliar with. If in
                    doubt, ASK!
                  </li>
                </ul>
              </FieldSet>
              <FieldSet>
                <h3>Waste Disposal</h3>
                <p>
                  Care must be taken when disposing of all waste materials to
                  prevent possible impacts on Health, Safety and the
                  Environment.
                </p>
                <ul>
                  <li>All waste materials must be suitably contained</li>
                  <li>
                    Do not discharge any chemicals/oils into the surface water
                    drains or sewers. Spill kits are available in and around the
                    process areas if required and all spillages must be reported
                    immediately to your Shift Manager or Team Leader.
                  </li>
                  <li>
                    All waste must be adequately segregated. (wood, metal,
                    cardboard, polythene, painted radiators, general)
                  </li>
                  <li>
                    Special waste such as oil contaminated absorbents and
                    containers with chemical residue cannot be treated as
                    general waste. Seek advice from the Safety Advisor before
                    disposal.
                  </li>
                  <li>
                    Only licensed waste carriers may remove waste from the
                    premises.
                  </li>
                </ul>
              </FieldSet>
              <FieldSet>
                <h3>Mechanical Handling Equipment (MHE) / Fork Lift</h3>
                <p>
                  Suitably qualified and authorised personnel must only use any
                  forklifts, pallet trucks.
                </p>
                <p>The following points must be adhered to:</p>
                <ul>
                  <li>
                    Make daily inspections of equipment and report any defects
                    immediately
                  </li>
                  <li>
                    Ensure that the load is suitably secured and carried in such
                    a manner that it cannot shift or move
                  </li>
                  <li>
                    Operate machinery in a safe manner at all times and be
                    particularly vigilant around pedestrians and rack exits
                  </li>
                  <li>
                    Sound horn when approaching blind corners and reduce your
                    speed
                  </li>
                  <li>Never exceed the safe working load</li>
                  <li>
                    Do not leave equipment suspended on mechanical lifts (forks)
                    when unattended
                  </li>
                  <li>
                    <b>Fork lift trucks must never carry passengers</b>
                  </li>
                  <li>Never use the forks of a truck as a working platform.</li>
                </ul>
                <p>
                  <b>
                    If you are in any doubt about your ability, or the ability
                    of a colleague to operate the equipment you should bring
                    this to the immediate attention of the Shift Manager.
                  </b>
                </p>
              </FieldSet>
              <FieldSet>
                <h3>Safety Signs</h3>
                <p>
                  Various types of safety sign are used around the workplace,
                  please be aware of their meaning:
                </p>
                <Col2Constant>
                  <div>
                    <h5>Warning</h5>
                    <SImage
                      src={SafetySignWarning}
                      alt="Yellow Triangle Warning Sign"
                    />
                  </div>
                  <div>
                    <h5>Prohibition</h5>
                    <SImage
                      src={SafetySignProhibition}
                      alt="Red Circle with Line Through Prohibition Sign"
                    />
                  </div>
                  <div>
                    <h5>Warning</h5>
                    <SImage
                      src={SafetySignMandatory}
                      alt="Blue Cirle Mandatory Sign"
                    />
                  </div>
                  <div>
                    <h5>Warning</h5>
                    <SImage
                      src={SafetySignSafeConditions}
                      alt="Green Square Safe Conditions Sign"
                    />
                  </div>
                </Col2Constant>
                <h3>Permits To Work</h3>
                <p>
                  Permits to work are required when performing the following
                  operations:
                </p>
                <ul>
                  <li>Hot Work (Non-production related activities)</li>
                  <li>Access to confined spaces</li>
                  <li>Working at height / roof work above 2 metres</li>
                  <li>High voltage electrical work</li>
                  <li>Work on the main compressed air system</li>
                  <li>
                    Any jobs or tasks where the absence of adequate controls
                    could result in serious injury, property damage or harm to
                    the environment
                  </li>
                </ul>
                <p>
                  Permits are available from the Safety Advisor or Engineering
                  Manager
                </p>
              </FieldSet>
              <FieldSet>
                <h2>Confirmation of Temporary Assignment:</h2>
                <p>
                  This document is to confirm you will be working on a contract
                  of services via Pin Point Recruitment at Rettig UK, on a
                  temporary contract:
                </p>
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Position:</b>
                      </td>
                      <td>Production Operative</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Location:</b>
                      </td>
                      <td>Team Valley, Eastern Ave, Gateshead NE11 0PG</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Reporting To:</b>
                      </td>
                      <td>
                        Paint Line – Bob Hope
                        <br />
                        Packaging – Cole Fisher
                        <br />
                        E-Rad – Chris Farrell
                        <br />
                        Welding Lines – Paul Hood
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Shift times:</b>
                      </td>
                      <td>
                        <p>
                          <b>Day Shift:</b> Monday to Thursday 6am – 4pm
                        </p>
                        <p>
                          <b>Late Shift:</b> Monday to Friday 12pm – 8pm
                        </p>
                        <p>
                          <b>Night Shift:</b> Monday to Thursday 8pm – 6am
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Contract Length:</b>
                      </td>
                      <td>Ongoing</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Training:</b>
                      </td>
                      <td>
                        Colleagues will take part in a company induction on site
                        lead by the on-shift Supervisor. Within this induction
                        health and safety/PPE requirements will be covered as
                        well as a full site tour.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Job Spec:</b>
                      </td>
                      <td>
                        <p>
                          <b>Paint Line –</b> Hanging products onto production
                          line, assembling small parts, preparing products for
                          painting and ensuring a high level of cleanliness is
                          maintained at all times.
                        </p>
                        <p>
                          <b>Packaging –</b> Preparing products for dispatch by
                          ensuring they are packed safely and correctly to
                          minimise the risk of damage.
                        </p>
                        <p>
                          <b>E-Rad –</b>Converting standard radiators into
                          electrical radiators, attaching electrical components
                          to product and filling with oil.
                        </p>
                        <p>
                          <b>Welding Lines –</b> Ensuring welding lines are
                          running consistently, packing and wrapping products at
                          the end of the line and ensuring they are stacked
                          correctly.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>PPE:</b>
                      </td>
                      <td>
                        Safety boots, yellow hi-vis, plain black or navy polo
                        top or t-shirt, black or navy work
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <br />
                <h3>Pay rates</h3>
                <TableEqualColumns>
                  <thead>
                    <tr>
                      <Sth>Role</Sth>
                      <Sth>Shift</Sth>
                      <Sth>Pre-12 weeks Pay rate</Sth>
                      <Sth>Post-12-weeks Pay rate</Sth>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td rowSpan="3">
                        <b>General Operative</b>
                      </td>
                      <td>Days</td>
                      <td>£8.21</td>
                      <td>£8.50</td>
                    </tr>
                    <tr>
                      <td>Nights</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Overtime Hours</td>
                      <td>£9.00</td>
                      <td>£9.31</td>
                    </tr>
                    <tr>
                      <td rowSpan="3">
                        <b>FLT Operator</b>
                      </td>
                      <td>Days</td>
                      <td>£8.45</td>
                      <td>£8.93</td>
                    </tr>
                    <tr>
                      <td>Nights</td>
                      <td>£8.65</td>
                      <td>£9.75</td>
                    </tr>
                    <tr>
                      <td>Overtime Hours</td>
                      <td>£9.75</td>
                      <td>£9.95</td>
                    </tr>
                    <tr>
                      <td rowSpan="3">
                        <b>Welding Lines</b>
                      </td>
                      <td>Days</td>
                      <td>£8.21</td>
                      <td>£8.93</td>
                    </tr>
                    <tr>
                      <td>Nights</td>
                      <td>£8.65</td>
                      <td>£9.75</td>
                    </tr>
                    <tr>
                      <td>Overtime Hours</td>
                      <td>£9.00</td>
                      <td>£9.95</td>
                    </tr>
                    <tr>
                      <td rowSpan="3">
                        <b>Electric Radiator</b>
                      </td>
                      <td>Days and Mids</td>
                      <td>£8.21</td>
                      <td>£8.93</td>
                    </tr>
                    <tr>
                      <td>Nights</td>
                      <td>£8.65</td>
                      <td>£9.75</td>
                    </tr>
                    <tr>
                      <td>Overtime Hours</td>
                      <td>£9.00</td>
                      <td>£9.95</td>
                    </tr>
                  </tbody>
                </TableEqualColumns>
              </FieldSet>
              <FieldSet>
                <h2>Absence Procedure</h2>
                <p>
                  In the event that you are unable to attend work due to
                  sickness or any other reason you must ensure you are following
                  the correct absence procedure in line with company policy.
                </p>
                <p>
                  <b>
                    Should you ever be absent from work you must contact Rettig
                    on their absence line: 0191 491 7455
                  </b>
                </p>
                <p>
                  <b>
                    Following this you must then notify Pin Point of your
                    absence on the details below:
                  </b>
                </p>
                <Col2>
                  <CardGrey>
                    <b>Gary Farrage</b>
                    <span>
                      Mobile: <a href="tel: 07736269779">07736 269 779</a>
                    </span>
                    <span>
                      Tel: <a href="tel: 01912615205">0191 261 5205</a>
                    </span>
                  </CardGrey>
                </Col2>
                <p>
                  Absence is monitored closely and failure to follow the correct
                  absence procedure could seriously affect your position within
                  the contract.
                </p>
                <p>
                  By signing this document you confirm that you understand the
                  absence procedure and understand the impact not following this
                  procedure my have on your position within the contract.
                </p>
              </FieldSet>
              <FieldSet>
                <FormikInput
                  name="full_name"
                  type="text"
                  label="Your full name"
                />
                <FormikCheckbox
                  bold={true}
                  label="I have read and understand the Rettig Absence Procedure and pay rates"
                  name="understands_absence_procedure"
                />
              </FieldSet>
              <Signature
                signatureDrawn={signatureDrawn}
                setSignatureDrawn={setSignatureDrawn}
                sigCanvas={sigCanvas}
              />
              {errorText
                ? errorText.map(e => (
                    <Error style={{ marginTop: "1rem" }} key={e}>
                      {e}
                    </Error>
                  ))
                : null}
              <SButton type="submit" disabled={isSubmitting}>
                Submit
              </SButton>
            </Form>
          )}
        </Formik>
      </LCard>
    </Layout>
  )
}

export default RettigFormGateshead
